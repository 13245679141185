import PropTypes from 'prop-types';
import { Badge, Typography } from '@bvcco/bvc-digital-market-component-library';

import {
  StyledDate,
  StyledEvent,
  StyledListItem,
  StyledContainer
} from './Event.styled';
import useDate from '@hooks/useDate';

const Event = ({ event, active, isMobile, ...rest }) => {
  const dateFormatter = useDate();
  const day = dateFormatter(event.date).format('DD');
  const month = dateFormatter(event.date).format('MMM');
  const formattedMonth =
    month.split('')[month.length - 1] === '.'
      ? month.substring(0, month.length - 1)
      : month;
  const hour = dateFormatter(event.date).format('h:mm a');
  const fullDate = dateFormatter(event.date).format('DD/MM/YY - h:mm a');

  return (
    <StyledListItem
      color='event'
      colorSeparator='transparent'
      colorType='dark'
      rounded
      selected={active}
      spacings={[5, 2, 2, 5]}
      showIndicator
      {...rest}
    >
      <StyledContainer>
        <StyledDate>
          <Typography type='h4' fontWeight='bold'>
            {day}
          </Typography>
          <Typography
            type='subtitle1'
            fontWeight='bold'
            textTransform='capitalize'
          >
            {formattedMonth}
          </Typography>
        </StyledDate>
        <StyledEvent active={active}>
          <Badge
            colorType='normal'
            textColor='secondary'
            fontWeight='bold'
            className='type'
          >
            {event.type}
          </Badge>
          <Typography
            as='h4'
            color='tertiary'
            type='subtitle2'
            fontWeight='bold'
            className='event-name'
          >
            {event.name}
          </Typography>
          {!isMobile && (
            <Typography color='tertiary' type='caption' as='p'>
              {event.description}
            </Typography>
          )}
          <Typography
            color='tertiary'
            fontWeight='lighter'
            type='caption'
            className='hour'
          >
            {isMobile ? hour : fullDate}
          </Typography>
        </StyledEvent>
      </StyledContainer>
    </StyledListItem>
  );
};
Event.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
      .isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  active: PropTypes.bool,
  isMobile: PropTypes.bool
};

Event.defaultProps = {
  active: false,
  isMobile: false
};

export default Event;
