import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

import StyledErrorMessage from './ErrorMessage.styled';
import { colorTypeProps } from '@config/common-propTypes';

const ErrorMessage = ({ bgColor, title, height, bgColorType, description }) => {
  const { t } = useTranslation();

  return (
    <StyledErrorMessage
      height={height}
      bgColor={bgColor}
      bgColorType={bgColorType}
    >
      {title && (
        <Typography
          color='primary'
          fontWeight='bold'
          type='h5'
          textAlign='center'
          className='errorMessage-title'
        >
          {t(title)}
        </Typography>
      )}
      {description && (
        <Typography
          color='primary'
          type='subtitle1'
          textAlign='center'
          className='errorMessage-description'
        >
          {t(description)}
        </Typography>
      )}
    </StyledErrorMessage>
  );
};

ErrorMessage.propTypes = {
  bgColor: PropTypes.string,
  bgColorType: colorTypeProps,
  title: PropTypes.string,
  height: PropTypes.string,
  description: PropTypes.string
};
ErrorMessage.defaultProps = {
  title: 'internalError.title',
  height: '500px',
  bgColor: 'localMarket',
  bgColorType: 'dark',
  description: 'internalError.description'
};

export default ErrorMessage;
