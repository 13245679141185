import PropTypes from 'prop-types';

import Loader from '@components/Loader/Loader';
import StyledContainer from './LocalMarketLoader.styled';

const LocalMarketLoader = ({ height }) => (
  <StyledContainer height={height}>
    <Loader />
  </StyledContainer>
);

LocalMarketLoader.propTypes = {
  height: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string
  })
};

LocalMarketLoader.defaultProps = {
  height: { desktop: '500px', mobile: '700px' }
};

export default LocalMarketLoader;
