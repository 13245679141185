import { useState } from 'react';

import useTable from './useTable';
import useMessage from './useMessage';
import { types } from '@config/constants';
import useCurrentData from './useCurrentData';
import useFirstRender from './useFirstRender';
import useTabSettings from './useTabSettings';
import useMarketConfig from './useMarketConfig';
import useInternalConfig from './useInternalConfig';
import validateMoreTradedFuture from '@utils/local-market/level-1/validateMoreTradedFuture';

const baseStructure = { table: [], graphics: [] };

export const initialStates = {
  [types.DIV]: {
    marketSpot: baseStructure,
    nextDay: baseStructure
  },
  [types.RF_PUB]: {
    publicMostTraded: baseStructure,
    privateMostTraded: baseStructure
  },
  [types.DER]: {
    futureMostTraded: baseStructure,
    optionsMostTraded: baseStructure
  },
  [types.RV]: {
    mostTraded: baseStructure,
    mostValued: baseStructure,
    mostDevalued: baseStructure
  }
};

const useTab = ({ type, tabsProp, setUpdateTickerData }) => {
  const initialState = initialStates[type];
  const [tabsData, setTabsData] = useState(initialState);

  const { selectedTab, setSelectedTab, properties } = useTabSettings({
    type,
    tabsProp
  });
  const { currentBreakpoint, isMobile } = useInternalConfig();
  const isMoreTradedFuture = validateMoreTradedFuture(tabsProp, selectedTab);
  const { marketConfig, marketIsOpen, hasToDivideVolumeBy1M, setMarketConfig } =
    useMarketConfig({
      type,
      tabsProp,
      properties,
      selectedTab,
      isMoreTradedFuture
    });

  useMessage({ type, setMarketConfig, setTabsData });
  const { error, isLoading } = useFirstRender({
    type,
    setMarketConfig,
    setTabsData,
    setUpdateTickerData
  });

  const currentData = useCurrentData({
    tabsData,
    properties
  });

  const { selectedRow, selectedRows, setSelectedRows } = useTable();

  return {
    error,
    tabsData,
    currentData,
    selectedRow,
    selectedRows,
    setSelectedRows,
    selectedTab,
    setSelectedTab,
    properties,
    marketConfig,
    marketIsOpen,
    setMarketConfig,
    isLoading,
    currentBreakpoint,
    isMobile,
    hasToDivideVolumeBy1M
  };
};

export default useTab;
