import { useState, useMemo } from 'react';

import { types, properties as constantsProperties } from '@config/constants';
import validateMarketByType from '@utils/local-market/level-1/validateMarketByType';

const useMarketConfig = ({ type, properties, ...args }) => {
  const [marketConfig, setMarketConfig] = useState({ status: {} });

  const hasToDivideVolumeBy1M = () =>
    properties !== constantsProperties.rf.most_traded_corporate_debt;

  const currentMarketConfig = useMemo(() => {
    if (type === types.RF_PUB) {
      return marketConfig[properties] || {};
    }
    return marketConfig;
  }, [type, properties, marketConfig]);

  const marketIsOpen = validateMarketByType(
    marketConfig,
    type,
    properties,
    args
  );

  return {
    marketIsOpen,
    setMarketConfig,
    marketConfig: currentMarketConfig,
    hasToDivideVolumeBy1M: hasToDivideVolumeBy1M()
  };
};

export default useMarketConfig;
