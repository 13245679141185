import PropTypes from 'prop-types';
import { childrenProps } from '@config/common-propTypes';
import { Button } from '@bvcco/bvc-digital-market-component-library';

const ButtonLink = ({
  icon,
  onClick,
  isButton,
  children,
  fontSize,
  ...rest
}) => (
  <Button
    fullWidth={false}
    withoutPadding={!isButton}
    as={isButton ? 'button' : 'a'}
    textProps={{
      colorType: 'normal',
      fontSize: fontSize || 'paragraph3',
      textDecoration: isButton ? 'none' : 'underline',
      color: isButton ? 'primary' : 'secondary'
    }}
    onClick={onClick}
    color={isButton ? 'primary' : 'transparent'}
    iconSpacing={4}
    endIcon={isButton ? undefined : icon}
    {...rest}
  >
    {children}
  </Button>
);

ButtonLink.propTypes = {
  icon: childrenProps,
  onClick: PropTypes.func,
  isButton: PropTypes.bool,
  children: childrenProps.isRequired,
  fontSize: PropTypes.string
};

ButtonLink.defaultProps = {
  icon: undefined,
  onClick: () => {},
  isButton: false,
  fontSize: undefined
};

export default ButtonLink;
