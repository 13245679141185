import { useState } from 'react';

const useTable = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRow = selectedRows[0];
  return {
    selectedRow,
    selectedRows,
    setSelectedRows
  };
};

export default useTable;
