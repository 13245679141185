import { useEffect } from 'react';

import useApiRequest from '@hooks/useApiRequest';

const useFirstRender = ({
  type,
  setMarketConfig,
  setTabsData,
  setUpdateTickerData
}) => {
  const [{ data, error, isLoading }] = useApiRequest({
    segment: type,
    endpoint: 'getMarketInformation'
  });

  useEffect(() => {
    if (data) {
      setMarketConfig(data.marketStatus);
      delete data.marketStatus;
      delete data.type;
      setTabsData(data);
      setUpdateTickerData && setUpdateTickerData(data);
    }
  }, [data]);

  return { error, isLoading };
};

export default useFirstRender;
