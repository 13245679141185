import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';

export const StyledCol = styled(Col)`
  ${({ theme }) => theme.breakpoints.md} {
    &:nth-of-type(2) {
      padding-right: ${({ theme }) => theme.utils.spacing(5)};
    }
    &:last-of-type {
      padding-left: ${({ theme }) => theme.utils.spacing(5)};
    }
  }
`;

export const StyledContainerDescription = styled.div`

  ${({ theme }) => theme.breakpoints.md} {
    margin-bottom: 10px;
  }
`;

export const StyledRow = styled(Row)`
   {
    
    ${({ theme }) => theme.breakpoints.xs} {
      padding: 10px;
    }
    ${({ theme }) => theme.breakpoints.md} {
      padding: 20px;
  }
   
  }
`;
