import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';
import {
  Typography,
  Ticker as TickerLib
} from '@bvcco/bvc-digital-market-component-library';

import App from '@config/app';
import { formatDecimal } from '@utils/numbers';
import { useMobile } from '@hooks/useBreakpoint';
import { generateNumberArray } from '@utils/arrays';
import { removeDashMnemonic } from '@utils/strings';
import StyledCloseMarketContainer from './Ticker.styled';
import useLevel3Redirect from '@hooks/local-market/useLevel3Redirect';
import { types, marketStatus, defaultSubMarkets } from '@config/constants';
import useConfig from '@hooks/useConfig';

const Ticker = ({ initialData, updateTickerData, showHeaderLogin }) => {
  const isMobile = useMobile();
  const redirectTo = useLevel3Redirect();
  const [fetchedData, setFetchedData] = useState(initialData);
  const [{ trm, speed, items, status, messageClosedMarket }, setTickerData] =
    useState(initialData);
  const isClosedMarket = status === marketStatus.CLOSE;
  const [{ language }] = useConfig();
  const tickerSpeed = isMobile ? speed * 2.5 : speed;

  const formatedItems = useMemo(
    () =>
      items?.map(({ value, mnemonic, percentage, ...item }) => {
        const symbol = '%';
        const decimals = 2;
        return {
          ...item,
          name: removeDashMnemonic(mnemonic),
          value: formatDecimal(value, {
            decimal: '.',
            separator: ',',
            decimals
          }),
          variation: {
            value: percentage,
            label: `${formatDecimal(percentage, { decimals: 2 })}${symbol}`
          }
        };
      }) || [],
    [items]
  );

  const alternativeContent = useMemo(
    () =>
      isClosedMarket ? (
        <StyledCloseMarketContainer>
          {generateNumberArray(8).map((number) => (
            <Typography
              key={number}
              color='danger'
              fontWeight='bold'
              textAlign='center'
            >
              {messageClosedMarket[language]}
            </Typography>
          ))}
        </StyledCloseMarketContainer>
      ) : null,
    [language, isClosedMarket]
  );

  const fetchTickerData = async () => {
    const { api } = App.getInstance();
    const response = await api.ticker.getTicker();
    if (response) {
      setFetchedData(response);

      if (response.status === 'OPEN') {
        setTickerData(response);
      }
    }
  };

  const updateTicker = () => {
    if (!isClosedMarket) {
      setTickerData(fetchedData);
      fetchTickerData();
    }
  };

  useEffect(() => {
    updateTicker();
  }, [updateTickerData]);

  useEffect(() => {
    fetchTickerData();
  }, []);
  if (updateTickerData) {
    return (
      <TickerLib
        isMobile={isMobile}
        items={formatedItems}
        onClick={({ name, market }) => {
          const formattedMarket = market === 'rf_pub' ? types.RF_PUB : market;
          redirectTo(formattedMarket, name, {
            field: 'mnemonic',
            subMarket: defaultSubMarkets[formattedMarket]
          });
        }}
        alternativeContentWidth={250}
        // onReloadTicker={updateTicker}
        alternativeContent={alternativeContent}
        durationItemsOnScreen={tickerSpeed}
        trm={formatDecimal(trm, { decimal: '.', decimals: 2, separator: ',' })}
        showHeaderLogin={showHeaderLogin}
      />
    );
  } else {
    return null;
  }
};

Ticker.propTypes = {
  initialData: PropTypes.shape({
    trm: PropTypes.number.isRequired,
    speed: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        market: PropTypes.string,
        mnemonic: PropTypes.string,
        percentage: PropTypes.number
      })
    )
  }),
  updateTickerData: PropTypes.shape({}),
  showHeaderLogin: PropTypes.bool
};

Ticker.defaultProps = {
  initialData: { items: [] },
  updateTickerData: null,
  showHeaderLogin: false
};

export default Ticker;
