/* eslint-disable react/forbid-prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab as TabLibrary,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import {
  tabMinWidth,
  breakpoints,
  reversedProperties
} from '@config/constants';
import Table from './Table/Table';
import Chart from './Chart/Chart';
import Header from './Header/Header';
import {
  StyledCol,
  StyledContainerDescription,
  StyledRow
} from '../Tab.styled';
import useTab from '@hooks/local-market/level-1/useTab';
import useDerTab from '@hooks/local-market/level-1/useDerTab';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import LocalMarketLoader from '@components/LocalMarketLoader/LocalMarketLoader';
import TableDisabled from '../../../MarketLevel2/Table/TableDisabled';

const Tab = ({
  tabs: tabsProp,
  type,
  defaultTab,
  onChangeTab,
  disabledMode,
  generalDescription,
  setUpdateTickerData
}) => {
  const {
    error,
    currentData,
    marketConfig,
    marketIsOpen,
    selectedRow,
    selectedRows,
    setSelectedRows,
    selectedTab,
    setSelectedTab,
    tabsData,
    isLoading,
    currentBreakpoint,
    isMobile
  } = useTab({ type, tabsProp, setUpdateTickerData });

  const { contracts, tradeVolume } = useDerTab({
    tabsProp,
    selectedTab,
    selectedRow,
    marketConfig
  });
  const renderedChart = useMemo(
    () => (
      <Chart
        data={currentData?.graphics || []}
        headerProps={{
          delay: marketConfig?.delay,
          marketIsOpen,
          mnemonic: selectedRow?.mnemonic,
          previousClosing: selectedRow?.variation?.closingPrice
        }}
        mnemonic={selectedRow?.mnemonic}
      />
    ),
    [currentData, selectedRow, marketIsOpen, marketConfig?.delay]
  );

  const renderedTabs = useMemo(
    () =>
      tabsProp.map(({ button, captions, columnNames, identifier, title }) =>
        (identifier === reversedProperties.der.optionsMostTraded &&
          tabsData?.optionsMostTraded?.table?.length > 0) ||
        identifier === reversedProperties.der.futureMostTraded ? (
          <TabLibrary
            key={identifier}
            title={title}
            as='h4'
            isDisabled={!disabledMode?.active}
          >
            {disabledMode?.active && (
              <Table
                data={currentData?.table || []}
                buttonTexts={captions}
                buttonContent={button}
                columnNames={columnNames}
                contracts={contracts}
                tradedVolume={tradeVolume}
                selectedRows={selectedRows}
                selectedTab={selectedTab}
                renderedMobileContent={renderedChart}
                updateSelectedRows={setSelectedRows}
              />
            )}
          </TabLibrary>
        ) : (
          <></>
        )
      ),
    [
      tabsProp,
      tabsData,
      selectedTab,
      contracts,
      renderedChart,
      tradeVolume,
      selectedRows
    ]
  );

  if (isLoading) {
    return <LocalMarketLoader />;
  }
  if (error) {
    return <ErrorMessage height='300px' />;
  }
  let borderStyles = {};
  if (disabledMode?.active)
    borderStyles = {
      dividerHeight: '120%',
      dividerColor: 'divider',
      dividerColorType: 'normal',
      borderColor: 'tableBorder'
    };
  return (
    <>
      <StyledRow>
        <StyledContainerDescription>
          <Typography color='infoGeneral' type='paragraph3' colorType='light'>
            {generalDescription}
          </Typography>
        </StyledContainerDescription>
        {disabledMode?.active && (
          <StyledCol xs={12} md={false}>
            <Header
              delay={marketConfig.delay}
              mnemonic={selectedRow?.mnemonic}
              previousClosing={selectedRow?.variation?.closingPrice}
              marketIsOpen={marketIsOpen}
            />
          </StyledCol>
        )}
        <StyledCol xs={12} md={6}>
          <Tabs
            centered={false}
            tabsFluid
            breakpoint={currentBreakpoint || breakpoints.SM}
            type={disabledMode?.active && 'hightLight'}
            bgColor='localMarket'
            defaultValue={defaultTab}
            onChange={(index) => {
              onChangeTab(index);
              setSelectedTab(index);
            }}
            bgColorType='dark'
            titleFontStyle={{
              fontColor: 'tertiary',
              fontColorType: 'normal',
              fontWeight: 'normal',
              selectedTabFontColor: 'secondary',
              selectedTabFontColorType: 'normal',
              selectedTabFontWeight: '900'
            }}
            tabMinWidth={tabMinWidth}
            isMobile={isMobile}
            {...borderStyles}
          >
            {renderedTabs}
          </Tabs>
        </StyledCol>

        {disabledMode?.active && (
          <StyledCol xs={false} md={6}>
            {renderedChart}
          </StyledCol>
        )}
      </StyledRow>
      {!disabledMode?.active && (
        <StyledRow>
          <StyledCol xs={12} md={12}>
            <TableDisabled
              title={disabledMode.disableMarketTitle}
              description={disabledMode.disableMarketDescription}
              buttonData={{
                link: disabledMode.disableMarketBtnUrl,
                title: disabledMode.disableMarketBtnTitle
              }}
              icon={disabledMode.settingsIcon}
            />
          </StyledCol>
        </StyledRow>
      )}
    </>
  );
};

Tab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
      }),
      captions: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      ),
      title: PropTypes.string,
      identifier: PropTypes.string,
      columnNames: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      )
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
  defaultTab: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  disabledMode: PropTypes.object.isRequired
};

export default Tab;
