/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import {
  Tab,
  Tabs,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import {
  types,
  tabMinWidth,
  breakpoints,
  socketsEvents,
  indexesProperties,
  marketStatus as marketStatusTypes
} from '@config/constants';
import Chart from './Chart/Chart';
import Table from './Table/Table';
import Header from './Header/Header';
import useApiRequest from '@hooks/useApiRequest';
import {
  StyledCol,
  StyledContainerDescription,
  StyledRow
} from '../Tab.styled';
import TableDisabled from '../../../MarketLevel2/Table/TableDisabled';
import useMultipleSocketIO from '@hooks/useMultipleSocketIO';
import useBreakpoint, { useMobile } from '@hooks/useBreakpoint';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import LocalMarketLoader from '@components/LocalMarketLoader/LocalMarketLoader';

export const initialState = {
  fixedRent: { table: [], graphics: [] },
  moneyMarket: { table: [], graphics: [] },
  stockholders: { table: [], graphics: [] }
};

const getNewPoint = (object) =>
  Object.keys(object).reduce((acc, key) => {
    if (key !== 'marketStatus') {
      acc.data = object[key];
      acc.key = key;
    }
    return acc;
  }, {});

const updateData = (message, data, tab) => {
  const newIndex = getNewPoint(message);
  const existIndex = !!data[tab]?.table.find(
    ({ index }) => newIndex.key === index
  );

  return {
    ...data,
    [tab]: {
      ...data[tab],
      table: existIndex
        ? data[tab]?.table.map((item) =>
            item.index === newIndex.key ? newIndex.data : item
          )
        : [...data[tab]?.table, newIndex.data],
      graphics: existIndex
        ? data[tab]?.graphics.map((item) =>
            item.index === newIndex.key
              ? { ...item, points: [...item.points, newIndex.data] }
              : item
          )
        : [
            ...data[tab]?.graphics,
            { index: newIndex.key, points: [newIndex.data] }
          ]
    }
  };
};

const InternalTab = ({
  tabs: tabsProp,
  selectedTab,
  setSelectedTab,
  disabledMode,
  generalDescription,
  setUpdateTickerData
}) => {
  const [{ message }, { subscribe, unsubscribeAll }] =
    useMultipleSocketIO('indexes');
  const [tabsData, setTabsData] = useState(initialState);
  const currentBreakpoint = useBreakpoint();
  const isMobile = useMobile();
  const [marketConfig, setMarketConfig] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [{ data, error, isLoading }, { fetchData }] = useApiRequest({
    segment: types.INDEXES,
    initialLoadingState: true,
    setUpdateTickerData
  });

  const marketIsOpen = marketConfig.status === marketStatusTypes.OPEN;
  const { identifier: globalIdentifier } = tabsProp[selectedTab];
  const indexesTab = indexesProperties[globalIdentifier];
  const selectedRow = selectedRows[0];
  const isMoneyMarket = indexesTab === indexesProperties.money_market;

  useEffect(() => {
    if (message.data) {
      if (message.data.marketStatus) {
        setMarketConfig(message.data.marketStatus);
      }
      delete message.data.marketStatus;
      if (message.data) {
        setTabsData((prev) => updateData(message.data, prev, message.key));
      }
    }
  }, [message]);

  useEffect(() => {
    if (data) {
      if (data.marketStatus) {
        setMarketConfig(data.marketStatus);
        delete data.marketStatus;
      }
      delete data.type;
      setTabsData({ [indexesTab]: data });
      setUpdateTickerData(data);
    }
  }, [data]);

  useEffect(() => {
    if (indexesTab === indexesProperties.fixed_rent) {
      fetchData(undefined, 'getFixedRentTab');
    } else if (indexesTab === indexesProperties.money_market) {
      fetchData(undefined, 'getMoneyMarketTab');
    } else {
      fetchData(undefined, 'getStockholders');
    }
  }, [indexesTab]);

  useEffect(() => {
    unsubscribeAll();
    if (indexesTab === indexesProperties.stockholders) {
      subscribe(
        socketsEvents.marketInformationLvl1IndexesRv,
        indexesProperties.stockholders
      );
    } else if (indexesTab === indexesProperties.fixed_rent) {
      subscribe(
        socketsEvents.marketInformationLvl1IndexesRfPub,
        indexesProperties.fixed_rent
      );
    } else {
      subscribe(
        socketsEvents.marketInformationLvl1IndexesColIbr,
        indexesProperties.money_market
      );
    }
  }, [indexesTab, subscribe, unsubscribeAll]);

  const currentData = useMemo(
    () => tabsData[indexesTab],
    [indexesTab, tabsData]
  );

  const renderedChart = useMemo(
    () => (
      <Chart
        data={currentData?.graphics || []}
        headerProps={{
          marketIsOpen,
          index: selectedRow?.index,
          previousClosing: selectedRow?.variation.closingPrice,
          showMarketStatus: !isMoneyMarket,
          isMoneyMarket
        }}
        index={selectedRow?.index}
        xAxisShowHour={!isMoneyMarket}
        isMoneyMarket={isMoneyMarket}
      />
    ),
    [currentData, selectedRow, marketIsOpen, isMoneyMarket]
  );

  const renderedTabs = useMemo(
    () =>
      tabsProp.map(({ button, captions, columnNames, identifier, title }) => (
        <Tab
          key={identifier}
          title={title}
          as='h4'
          isDisabled={!disabledMode?.active}
        >
          {disabledMode?.active && (
            <Table
              data={tabsData[indexesProperties[identifier]]?.table || []}
              buttonTexts={captions}
              buttonContent={button}
              columnNames={columnNames}
              tradedVolume={marketConfig?.tradedVolume}
              selectedRows={selectedRows}
              selectedTab={selectedTab}
              renderedMobileContent={renderedChart}
              updateSelectedRows={setSelectedRows}
            />
          )}
        </Tab>
      )),
    [
      tabsProp,
      tabsData,
      selectedTab,
      renderedChart,
      selectedRows,
      marketConfig.tradedVolume
    ]
  );

  useEffect(() => {
    if (isMobile) {
      setSelectedRows([]);
    }
  }, [selectedTab]);

  if (isLoading) {
    return <LocalMarketLoader />;
  }
  if (error) {
    return <ErrorMessage height='300px' />;
  }

  let borderStyles = {};
  if (disabledMode?.active)
    borderStyles = {
      dividerHeight: '120%',
      dividerColor: 'divider',
      dividerColorType: 'normal',
      borderColor: 'tableBorder'
    };
  return (
    <>
      <StyledRow>
        <StyledContainerDescription>
          <Typography color='infoGeneral' type='paragraph3' colorType='light'>
            {generalDescription}
          </Typography>
        </StyledContainerDescription>

        {disabledMode?.active && (
          <StyledCol xs={12} md={false}>
            <Header
              index={selectedRow?.index}
              previousClosing={selectedRow?.variation.closingPrice}
              marketIsOpen={marketIsOpen}
              showMarketStatus={!isMoneyMarket}
            />
          </StyledCol>
        )}
        <StyledCol xs={12} md={6}>
          <Tabs
            centered={false}
            tabsFluid
            breakpoint={currentBreakpoint || breakpoints.SM}
            type={disabledMode?.active && 'hightLight'}
            bgColor='localMarket'
            defaultValue={selectedTab}
            onChange={setSelectedTab}
            bgColorType='dark'
            isMobile={isMobile}
            titleFontStyle={{
              fontColor: 'tertiary',
              fontColorType: 'normal',
              fontWeight: 'normal',
              selectedTabFontColor: 'secondary',
              selectedTabFontColorType: 'normal',
              selectedTabFontWeight: '900'
            }}
            tabMinWidth={tabMinWidth}
            {...borderStyles}
          >
            {renderedTabs}
          </Tabs>
        </StyledCol>
        {disabledMode?.active && (
          <StyledCol xs={false} md={6}>
            {renderedChart}
          </StyledCol>
        )}
      </StyledRow>
      {!disabledMode?.active && (
        <StyledRow>
          <StyledCol xs={12} md={12}>
            <TableDisabled
              title={disabledMode.disableMarketTitle}
              description={disabledMode.disableMarketDescription}
              buttonData={{
                link: disabledMode.disableMarketBtnUrl,
                title: disabledMode.disableMarketBtnTitle
              }}
              icon={disabledMode.settingsIcon}
            />
          </StyledCol>
        </StyledRow>
      )}
    </>
  );
};

InternalTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
      }),
      captions: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      ),
      title: PropTypes.string,
      identifier: PropTypes.string,
      columnNames: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      )
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  disabledMode: PropTypes.object.isRequired
};

export default InternalTab;
