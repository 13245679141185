import { InView } from 'react-intersection-observer';

const withLazyLoad = (Component) => (props) =>
  (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <div ref={ref}>
          {inView && <Component inView={inView} {...props} />}
        </div>
      )}
    </InView>
  );

export default withLazyLoad;
