import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme, height, bgColor, bgColorType }) => css`
    height: ${height};
    background-color: ${theme.colors.bg[bgColor][bgColorType]};
    padding: ${theme.utils.spacing(4, 8)};
    width: fill-available;
    .errorMessage-title {
      line-height: ${theme.font.lineHeight.small};
      margin-bottom: ${theme.utils.spacing(1)};
    }
  `};
`;
