import { useMemo } from 'react';

const useCurrentData = ({ tabsData, properties }) => {
  const currentData = useMemo(() => {
    const data = tabsData[properties];
    return data;
  }, [tabsData, properties]);
  return currentData;
};

export default useCurrentData;
