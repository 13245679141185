import styled from 'styled-components';
import { Select } from '@bvcco/bvc-digital-market-component-library';

export const StyledContainer = styled.section`
  margin-top: ${({ theme }) => theme.utils.spacing(8)};
  .calendar-events {
    margin-bottom: ${({ theme }) => theme.utils.spacing(5)};
  }
`;

export const StyledEventsContainer = styled.div`
  height: 313px;
  width: calc(100% - ${({ theme }) => theme.utils.spacing(3)});
  padding-right: ${({ theme }) => theme.utils.spacing(3)};
  margin: ${({ theme }) => theme.utils.spacing(4, 0)};
  .scroll {
    padding-right: ${({ theme }) => theme.utils.spacing(3)};
  }
`;

export const StyledNoEventsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const StyledSelect = styled(Select)`
  min-width: 300px;
  margin: 0 ${({ theme }) => theme.utils.spacing(8)} 0 auto;
  margin-top: ${({ theme }) => theme.utils.spacing(4)};
  margin-bottom: ${({ theme }) => theme.utils.spacing(4)};
  width: auto;

  ${({ theme }) => theme.breakpoints.md} {
    margin-top: 0;
    width: fit-content;
  }
`;
