import useBreakpoint, { useMobile } from '@hooks/useBreakpoint';

const useInternalConfig = () => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = useMobile();

  return {
    currentBreakpoint,
    isMobile
  };
};

export default useInternalConfig;
