// import io from 'socket.io-client';
import appConfig from '../config/config';

import { useEffect, useState, useCallback } from 'react';

const parseConfig = (configString, param) => {
  const [serviceName, eventName] = configString.split('.');

  const event = appConfig.websockets[serviceName]?.events[eventName];
  return [
    appConfig.websockets[serviceName]?.baseURL,
    param ? `${event}-${param}` : event
  ];
};

const useSocketIO = ({ config, param, socketOptions }) => {
  const [url, event] =
    typeof config === 'string'
      ? parseConfig(config, param)
      : [config?.url, param ? `${config?.event}-${param}` : config?.event];
  const [socket, setSocket] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();

  const setErrorListeners = useCallback(() => {
    if (socket) {
      socket.on('error', () => {
        setError(' An error event was sent from the server.');
      });
    }
  }, [socket]);

  const subscribe = useCallback(
    (alternativeParam) => {
      if (event && socket) {
        const alternativeEvent = alternativeParam
          ? `${event}-${alternativeParam}`
          : '';
        socket.on(alternativeEvent || event, (data) => {
          setMessage(data);
        });
      }
    },
    [socket, event]
  );

  const unsubscribe = useCallback(() => {
    if (socket) {
      socket.removeAllListeners();
      socket.close();
    }
  }, [socket]);

  useEffect(() => {
    // setSocket(io(url, { ...socketOptions, transports: ['websocket'] }));
  }, [url, socketOptions]);

  useEffect(() => {
    setErrorListeners();
    // subscribe();
    return unsubscribe;
  }, [subscribe, unsubscribe, setErrorListeners]);

  return [
    { message, error },
    { subscribe, unsubscribe, socket }
  ];
};

export default useSocketIO;
