import styled from 'styled-components';

export const StyledEmptyContainer = styled.section`
  $mobile: 768px;
  $tablet: 1023px;
  $desktop: 1215px;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  padding-top: 130px;
  padding-bottom: 130px;

  .section-content {
    h2, h5 {
      padding-top: 15px;
      padding-bottom: 15px;
      color: #FFF;
    }

    @media screen and (max-width: 768px) {
      width: 80%;
      
      h2 {
        font-size: 22px;
        line-height: 26.4px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: center;
      }

      h5 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
      }
    }

    @media screen and (min-width: 769px) {
      width: 45%;

      h2 {
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.02em;
        text-align: center;
      }

      h5 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: center;
      }
    }
  }

  button {
    margin-top: 10px;
    padding: 12px 30px 12px 30px;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-align: center;

    &:hover{
      background-color: rgb(44, 86, 151);
      border: 1px solid rgb(44, 86, 151);
      cursor: pointer;
    }
  }

  svg {
    padding-bottom: 20px;
  }
`;