import validateMoreTradedFuture from '@utils/local-market/level-1/validateMoreTradedFuture';

const useDerTab = ({ tabsProp, selectedTab, marketConfig }) => {
  const isMoreTradedFuture = validateMoreTradedFuture(tabsProp, selectedTab);
  return {
    contracts: isMoreTradedFuture
      ? marketConfig?.futureContracts
      : marketConfig?.optionalContracts,
    tradeVolume: isMoreTradedFuture
      ? marketConfig?.futureTotalVolume
      : marketConfig?.optionalTotalVolume
  };
};

export default useDerTab;
