// import io from 'socket.io-client';
import { useEffect, useState, useCallback } from 'react';

import appConfig from '../config/config';

const useMultipleSocketIO = (url, socketOptions) => {
  const socketUrl = appConfig.websockets[url]?.baseURL;
  const [socket, setSocket] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState({ key: '', data: null });

  const setErrorListeners = useCallback(() => {
    if (socket) {
      socket.on('error', () => {
        setError(' An error event was sent from the server.');
      });
    }
  }, [socket]);

  const subscribe = useCallback(
    (eventName, key) => {
      if (socket) {
        socket.on(eventName, (data) => {
          setMessage({ key, data });
        });
      }
    },
    [socket]
  );

  const close = useCallback(() => {
    if (socket) {
      socket.close();
    }
  }, [socket]);

  const unsubscribe = useCallback(
    (eventName) => {
      if (socket) {
        socket.removeListener(eventName);
      }
    },
    [socket]
  );

  const unsubscribeAll = useCallback(() => {
    if (socket) {
      socket.removeAllListeners();
    }
  }, [socket]);

  useEffect(() => {
    // setSocket(io(socketUrl, { ...socketOptions, transports: ['websocket'] }));
  }, [socketUrl, socketOptions]);

  useEffect(() => {
    setErrorListeners();
    return close;
  }, [subscribe, close, setErrorListeners]);

  return [
    { message, error },
    { subscribe, unsubscribe, unsubscribeAll, socket, close }
  ];
};

export default useMultipleSocketIO;
