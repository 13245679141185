import { useEffect, useState } from 'react';

import useSocketIO from '@hooks/useSocketIO';
import { types, sockets } from '@config/constants';
import tokenGenerator from '@utils/tokenGenerator';

const useMessage = ({ type, setMarketConfig, setTabsData }) => {
  const [token, setToken] = useState('');
  const [{ message }, { socket }] = useSocketIO({
    config: sockets.marketInformationN1[type]
  });

  useEffect(() => {
    const generateToken = async () => {
      setToken(await tokenGenerator());
    };

    generateToken();
  }, []);

  useEffect(() => {
    if (socket && token) {
      socket.emit(sockets.authenticate.eventName, { token });
    }
  }, [token, socket]);

  useEffect(() => {
    if (message) {
      if (message.marketStatus) {
        setMarketConfig(message.marketStatus);
      } else if (type === types.RF_PUB) {
        const keys = Object.keys(message);
        setMarketConfig((prev) =>
          keys.reduce((acc, value) => {
            if (message[value].marketStatus) {
              acc[value] = message[value].marketStatus;
            }
            return acc;
          }, prev)
        );
      }
      delete message.marketStatus;
      setTabsData(message);
    }
  }, [message]);
};

export default useMessage;
