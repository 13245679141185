import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Tabs } from '@bvcco/bvc-digital-market-component-library';

export const CustomRow = styled(Row)`
  margin-top: ${({ theme, isMobile }) =>
    isMobile ? theme.utils.spacing(0) : theme.utils.spacing(8)};
  margin-bottom: ${({ theme }) => theme.utils.spacing(8)};
`;

export const ClickeableCol = styled(Col)`
  cursor: pointer;
  padding: 1px;
  overflow: hidden;
`;

export const NewsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.utils.spacing(12)};
`;

export const StyledRow = styled(Row)`
  min-height: 350px;
  ${({ theme }) => theme.breakpoints.md} {
    min-height: 626px;
  }
`;

export const StyledTabs = styled(Tabs)`
  & li.tabs span {
    bottom: ${({ theme }) => theme.utils.spacing(0.5)}!important;
  }
`;
