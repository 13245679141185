import PropTypes from 'prop-types';
import { StyledEmptyContainer } from './TableDisabled.styled';
import ButtonLink from '@components/ButtonLink/ButtonLink';
import useRouter from '@hooks/useRouter';

const TableDisabled = ({ title, description, buttonData, icon }) => {
  const router = useRouter();

  const onClickLink = (link) => {
    router.push(link);
  };

  return (
    <StyledEmptyContainer>
      <img src={icon.url} alt={title} />
      <div className='section-content'>
        <h2> {title} </h2>
        <h5> {description} </h5>
      </div>
      <ButtonLink
        isButton
        onClick={() => {
          onClickLink(buttonData.link);
        }}
        fontSize='paragraph4'
      >
        {buttonData.title}
      </ButtonLink>
    </StyledEmptyContainer>
  );
};

TableDisabled.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonData: PropTypes.object.isRequired
};

export default TableDisabled;
