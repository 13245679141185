import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useRouter from '@hooks/useRouter';
import { tabsIdentifiers, tabsIdentifiersToTypes } from '@config/constants';

/**
 * Hook for redirect to pages of market lvl 3.
 * @returns {Function} Return.function for redirect.
 */
const useLevel3Redirect = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const validateMarket = (market, identifier) =>
    market === identifier ||
    market === tabsIdentifiersToTypes[identifier?.toLowerCase()];

  const redirectTo = useCallback(
    (market, issuerOrIndex, { field, subMarket } = {}) => {
      let path;
      let tabAnchor = '';
      switch (true) {
        case validateMarket(market, tabsIdentifiers.INDEXES):
          path = t('indexesPath');
          break;

        case validateMarket(market, tabsIdentifiers.DERIVATIVES):
          path = t('derivativesPath');
          break;

        case validateMarket(market, tabsIdentifiers.VARIABLE_INCOME): {
          const isIssuer = field === 'issuer';
          const aditionalParam = isIssuer ? '&issuer' : '';
          tabAnchor =
            isIssuer || !field
              ? `${t('variableIncomePath.tabs.information')}${aditionalParam}`
              : t('variableIncomePath.tabs.summary');
          path = `${t('variableIncomePath.path')}-${t(
            `variableIncomePath.subPaths.${subMarket}`
          )}`;
          break;
        }
        case validateMarket(market, tabsIdentifiers.FIXED_RENT): {
          path = `${t('fixedRentPath.path')}-${t(
            `fixedRentPath.subPaths.${subMarket}`
          )}`;
          break;
        }
        default:
          break;
      }
      if (path) {
        router.push(`${path}/${issuerOrIndex}${tabAnchor}`.toLowerCase());
      }
    },
    [t, router]
  );

  return redirectTo;
};

export default useLevel3Redirect;
