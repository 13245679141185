/* eslint-disable react/forbid-prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab as TabLibrary,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import Table from './Table/Table';
import Chart from './Chart/Chart';
import Header from './Header/Header';
import {
  StyledCol,
  StyledContainerDescription,
  StyledRow
} from '../Tab.styled';
import HorizontalChart from './Chart/HorizontalChart';
import useTab from '@hooks/local-market/level-1/useTab';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { tabMinWidth, breakpoints, properties } from '@config/constants';
import LocalMarketLoader from '@components/LocalMarketLoader/LocalMarketLoader';
import TableDisabled from '../../../MarketLevel2/Table/TableDisabled';

const Tab = ({
  tabs: tabsProp,
  type,
  defaultTab,
  onChangeTab,
  disabledMode,
  hideGraphicLevelOne,
  generalDescription,
  setUpdateTickerData
}) => {
  const {
    error,
    currentData,
    marketConfig,
    marketIsOpen,
    selectedRow,
    selectedRows,
    setSelectedRows,
    selectedTab,
    setSelectedTab,
    isLoading,
    currentBreakpoint,
    isMobile,
    properties: currentTab,
    hasToDivideVolumeBy1M
  } = useTab({ type, tabsProp, setUpdateTickerData });

  const renderedChart = useMemo(
    () =>
      currentTab === properties.rf.most_traded_public_debt ? (
        <Chart
          data={currentData?.graphics || []}
          headerProps={{
            delay: marketConfig?.delay,
            marketIsOpen,
            title: selectedRow?.mnemonic,
            referenceRate: selectedRow?.referenceRate,
            priceOrYield: selectedRow?.priceOrYield
          }}
          mnemonic={selectedRow?.mnemonic}
          fullNameMnemonic={selectedRow?.fullMnemonic}
          hideGraphicLevelOne={hideGraphicLevelOne}
        />
      ) : (
        <HorizontalChart
          data={currentData?.table || []}
          headerProps={{
            delay: marketConfig?.delay,
            marketIsOpen
          }}
          titleClass={selectedRow?.titleClass}
          currentTab={currentTab}
          hideGraphicLevelOne={hideGraphicLevelOne}
        />
      ),
    [currentData, selectedRow, marketIsOpen, marketConfig?.delay, currentTab]
  );

  const renderedTabs = useMemo(
    () =>
      tabsProp.map(({ button, captions, columnNames, identifier, title }) => (
        <TabLibrary
          key={identifier}
          title={title}
          as='h4'
          isDisabled={!disabledMode?.active}
        >
          {disabledMode?.active && (
            <Table
              data={currentData?.table || []}
              buttonTexts={captions}
              buttonContent={button}
              columnNames={columnNames}
              tradedVolume={marketConfig?.tradedVolume}
              registeredVolume={marketConfig?.registeredVolume}
              selectedRows={selectedRows}
              selectedTab={selectedTab}
              renderedMobileContent={renderedChart}
              updateSelectedRows={setSelectedRows}
              currentTab={currentTab}
              hasToDivideVolumeBy1M={hasToDivideVolumeBy1M}
            />
          )}
        </TabLibrary>
      )),
    [
      tabsProp,
      selectedTab,
      currentData,
      selectedRows,
      marketConfig,
      renderedChart,
      setSelectedRows,
      currentTab
    ]
  );

  if (isLoading) {
    return <LocalMarketLoader />;
  }
  if (error) {
    return <ErrorMessage height='300px' />;
  }
  let borderStyles = {};
  if (disabledMode?.active)
    borderStyles = {
      dividerHeight: '120%',
      dividerColor: 'divider',
      dividerColorType: 'normal',
      borderColor: 'tableBorder'
    };
  return (
    <>
      <StyledRow>
        <StyledContainerDescription>
          <Typography color='infoGeneral' type='paragraph3' colorType='light'>
            {generalDescription}
          </Typography>
        </StyledContainerDescription>
        {disabledMode?.active && (
          <StyledCol xs={12} md={false}>
            <Header
              delay={marketConfig.delay}
              mnemonic={selectedRow?.mnemonic}
              referenceRate={selectedRow?.referenceRate}
              marketIsOpen={marketIsOpen}
            />
          </StyledCol>
        )}
        <StyledCol xs={12} md={6}>
          <Tabs
            centered={false}
            tabsFluid
            breakpoint={currentBreakpoint || breakpoints.SM}
            type={disabledMode?.active && 'hightLight'}
            bgColor='localMarket'
            defaultValue={defaultTab}
            onChange={(index) => {
              onChangeTab(index);
              setSelectedTab(index);
            }}
            bgColorType='dark'
            titleFontStyle={{
              fontColor: 'tertiary',
              fontColorType: 'normal',
              fontWeight: 'normal',
              selectedTabFontColor: 'secondary',
              selectedTabFontColorType: 'normal',
              selectedTabFontWeight: '900'
            }}
            isMobile={isMobile}
            tabMinWidth={tabMinWidth}
            {...borderStyles}
          >
            {renderedTabs}
          </Tabs>
        </StyledCol>
        {disabledMode?.active && (
          <StyledCol xs={false} md={6}>
            {renderedChart}
          </StyledCol>
        )}
      </StyledRow>
      {!disabledMode?.active && (
        <StyledRow>
          <StyledCol xs={12} md={12}>
            <TableDisabled
              title={disabledMode.disableMarketTitle}
              description={disabledMode.disableMarketDescription}
              buttonData={{
                link: disabledMode.disableMarketBtnUrl,
                title: disabledMode.disableMarketBtnTitle
              }}
              icon={disabledMode.settingsIcon}
            />
          </StyledCol>
        </StyledRow>
      )}
    </>
  );
};

Tab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
      }),
      captions: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      ),
      title: PropTypes.string,
      identifier: PropTypes.string,
      columnNames: PropTypes.arrayOf(
        PropTypes.shape({ description: PropTypes.string })
      )
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
  defaultTab: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  disabledMode: PropTypes.object.isRequired,
  hideGraphicLevelOne: PropTypes.object.isRequired
};

export default Tab;
