import { marketStatus as marketStatusTypes, types } from '@config/constants';

const validateMarketByType = (marketConfig, type, properties, args = {}) => {
  if (type === types.RF_PUB) {
    return marketConfig[properties]?.status === marketStatusTypes.OPEN;
  } else if (type === types.DIV || type === types.RV) {
    return marketConfig?.status === marketStatusTypes.OPEN;
  } else if (type === types.DER) {
    const { isMoreTradedFuture } = args;
    const statusOption = isMoreTradedFuture ? 'isFutureOpen' : 'isOptionOpen';
    return marketConfig?.status[statusOption] === marketStatusTypes.OPEN;
  }
};

export default validateMarketByType;
