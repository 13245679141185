import { useMemo } from 'react';

import withLazyLoad from '@hoc/withLazyLoad';
import SquareNovelty from './SquareNovelty/SquareNovelty';
import { propTypes, defaultProps } from './noveltyPropTypes';
import RectangularNovelty from './RectangularNovelty/RectangularNovelty';
import { useState } from 'react';

const Novelty = ({ type, index, breakpoint, ...rest }) => {
  const [isActiveHover, setIsActiveHover] = useState(false);
  const transition = useMemo(
    () => ({
      duration: 0.4,
      delay: index / 5,
      easing: [0.42, 0, 0.58, 1]
    }),
    [index]
  );

  const variants = {
    hidden: {
      scale: 0,
      opacity: 0,
      transition
    },
    show: {
      scale: 1,
      opacity: 1,
      transition
    }
  };

  if (type === 'rectangle') {
    return (
      <RectangularNovelty
        {...rest}
        exit='hidden'
        animate='show'
        initial='hidden'
        variants={variants}
        breakpoint={breakpoint}
        isActiveHover={isActiveHover}
        onMouseEnter={() =>{setIsActiveHover(true)}}
        onMouseLeave={() =>{setIsActiveHover(false)}}
      />
    );
  }

  return (
    <SquareNovelty
      {...rest}
      exit='hidden'
      animate='show'
      initial='hidden'
      variants={variants}
      breakpoint={breakpoint}
      isActiveHover={isActiveHover}
      onMouseEnter={() =>{setIsActiveHover(true)}}
      onMouseLeave={() =>{setIsActiveHover(false)}}
    />
  );
};

Novelty.propTypes = propTypes;

Novelty.defaultProps = defaultProps;

export default withLazyLoad(Novelty);
