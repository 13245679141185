import { useState } from 'react';

import { properties } from '@config/constants';

const useTabSettings = ({ type, tabsProp }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { identifier } = tabsProp[selectedTab] || {};
  const internalProperties = properties[type][identifier];

  return {
    selectedTab,
    setSelectedTab,
    properties: internalProperties
  };
};

export default useTabSettings;
