import styled from 'styled-components';

export default styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${({ height }) => height.mobile};
  width: 100%;

  ${({ theme }) => theme.breakpoints.md} {
    padding-top: ${({ theme }) => theme.utils.spacing(6.25)};
    height: ${({ height }) => height.desktop};
  }
`;
